import React, { useState } from 'react'
import ReactPlayer from "react-player";
import os01 from "./os01.json";
import os02 from "./os02.json";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

function App() {
  const [selectedItem, setSelectedItem] = useState(os01[0].Link)
  const [data, setData] = useState(os01.concat(os02))
  const [filter, setFilter] = useState('')
  const [video, isVideo] = useState(true)

  return (
    <div className="container-fluid pt-5">
      <div className="row mb-3">
        <div className="col-6 col-md-3 col-lg-2 mx-auto">
          <img src="denizkultur.png" className="img-fluid" />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-10 col-md-4 mx-auto">
          <img src="title.png" className="img-fluid" />
        </div>
      </div>
      <div className="row mb-3 d-flex justify-content-center g-0">
        <div className="col-md-5 order-2 order-md-1">
          <div className='player-wrapper2 bg-white overflow-y-scroll'>
            <div className='card w-100 bg-transparent react-player rounded-0'>
              <div className='card-body'>
                <table className='table table-striped table-hover'>
                  <thead>
                    <tr>
                      <td colSpan={2}>
                        <input value={filter} onChange={(e) => setFilter(e.target.value)} className="form-control" type="search" placeholder="Search" />
                      </td>
                    </tr>
                  </thead>
                  <tbody className='border'>
                    {data ? data.filter(x => x.Title.toLocaleLowerCase().includes(filter.toLocaleLowerCase())).map((x, i) => <tr key={i}>
                      <td>
                        <h5>{x.Title.split(" | ")[0]}</h5>
                        <p className='text-muted m-0'>
                          {x.Title.split(" | ")[1]}<br />
                          {x.Title.split(" | ")[2]}
                        </p>
                      </td>
                      <td>
                        <button className='btn d-block my-1 w-100 btn-danger btn-sm' onClick={() => { setSelectedItem(x.Link); isVideo(true) }}><i className="fa-brands fa-youtube"></i></button>
                        <button className='btn d-block my-1 w-100 btn-info btn-sm' onClick={() => { setSelectedItem(x.Index); isVideo(false) }}><i className="fa-solid fa-music"></i></button>
                      </td>
                    </tr>) : <span>Yükleniyor...</span>}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5 order-1 order-md-2 mb-3">
          <div className="player-wrapper">
            {
              video ?
                <ReactPlayer className="react-player" url={selectedItem} playing={true} width={"100%"} height={"100%"} /> :
                <AudioPlayer className="react-player" autoPlay src={"/audio/"+selectedItem+".mp3"} />
            }
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6 col-md-3 col-lg-2 mx-auto">
          <img src="denizbank.png" className="img-fluid" />
        </div>
      </div>
    </div>
  );
}

export default App;
